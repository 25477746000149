*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: '"Poppins", sans-serif';
  scroll-behavior: smooth;
}

a{
  text-decoration: none;
}
